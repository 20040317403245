// Grid system
//
// @author Am!n <ivahid.com>
// @copyright 2017 ivahid.com
//
make-cols-array( max = grid-columns )
  ary = ( 'auto' )
  for i in (0..max)
    push( ary, i )

  return ary

grid-row()
  display flex
  flex-wrap wrap
  list-style none

grid-col( size = 0, single = true )
  if( single )
    position relative
    width 100%
    min-height 1px
    padding-right ( grid-gutter-width / 2 )
    padding-left ( grid-gutter-width / 2 )

  // Make equal columns
  if( size == 0 )
    flex-basis 0
    flex-grow 1
    max-width 100%

  // Auto width columns
  else if( size == 'auto' )
    flex 0 0 auto
    width auto
    max-width none

  // Custom size columns
  else
    flex 0 0 percentage( size / grid-columns )
    max-width percentage( size / grid-columns )

grid-col-offset( size = 1 )
  offset = size / grid-columns
  margin-left offset is not 0 ? percentage( offset ) : 0

  :root[dir=rtl] &
    margin-right offset is not 0 ? percentage( offset ) : 0

make-grid-columns( breakpoint = '' )
  if( ! breakpoint )
    return

  suffix = '-' + breakpoint

  // col classes
  .col
    grid-col(0, false)

  for i in make-cols-array()
    count = i is not 0 ? '-' + i : ''
    .col{suffix}{count}
      grid-col(i, false)

  // offset classes
  for i in (1..grid-columns)
    count =  '-' + i
    .offset{suffix}{count}
      grid-col-offset(i)

  // order first
  .order{suffix}-first
    order -1

  // other orders
  for i in (1..grid-columns)
    count = i is not 0 ? '-' + i : ''

    .order{suffix}{count}
      order i


// make flex utitlities ( removed )
// make-grid-flex-utils( breakpoint = '' )
//   if( ! breakpoint )
//     return
//
//   suffix =  breakpoint is 'xs' ? '': '-' + breakpoint
