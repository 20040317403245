push-center( direction = 'both' )
  if( direction is 'both' || direction is 'vertical' )
    top 50%
    transform translateY(-50%)

  if( direction is 'both' || direction is 'horizental' )
    left 50%
    transform translateX(-50%)

margin-auto()
  margin-left auto
  margin-right auto

align-middle()
  position relative
  top 50%
  transform translateY(-50%)

aligncenter( margin = 20px, unknown = false )
  margin-left auto
  margin-right auto
  if( unknown )
    display table
  else
    display block
  margin-bottom margin

alignright()
  float right

alignleft()
  float left

self-center()
  align-self center