/*
 * Archive styles
*/
elements-transition = unquote("all 500ms " + easing('in-out-quad'))

.blogposts-item-wrap
  margin-bottom 40px
  transition elements-transition

.blogpost-item
  transition elements-transition
  &:hover
    .post-txt
      .right-icon
        background-color #6001ff
        .right-arrow
          fill #fff

  .post-image
      position relative
      z-index 10
      transition elements-transition

      img
        border-radius 10px

    .post-txt
      transition elements-transition

      .comment-txt
        margin-top 15px
        margin-right 5px
        color #1b2c7a
        font-size 12px
        font-weight 400

        .like-txt ,
        .lookout-txt
          margin-left 40px

          +breakpoint-down('lg')
            margin-left 33px
          a
            color #1b2c7a
            font-size 12px
            font-weight 400
          .heart ,
          .message-circle
            width 16px
            height 16px
            stroke #6001ff
            margin-left 5px
            fill rgba(0, 0, 0, 0)

          .message-circle
            stroke #ddc317

        .txts
          color #6001ff
          font-size 11px
          font-weight 400
          padding-left 10px
          line-height 26px
          height 26px
          border-radius 13px
          background-color #f2f3f8
          display inline-block
          padding 0px 11px

    .text-box
      box-shadow 3px 11px 6px rgba(96, 1, 255, 0.02)
      border-radius 10px
      background-color #ffffff
      position relative
      z-index 20
      margin-right -60px
      margin-top 10px
      padding 25px 65px

      .news-post , .point-icon
        display none
      h2
        color #111c4c
        font-size 16px
        font-weight 400
        margin-bottom 3px

      .date
        color #111c4c
        font-size 11px
        font-weight 300
        margin-bottom 20px

      .abstract
        margin-top 10px
        p
          color #2e2e2e
          font-size 12px
          font-weight 400
          text-align justify


    .right-icon
      position absolute
      top 50%
      z-index 1000
      left -1%
      transform rotate(180deg)
      width 50px
      height 50px
      background-color #f2f3f8
      border-radius 100%
      display inline-block
      line-height 50px
      text-align center
      transition all 200ms ease-in-out
      transform translateY(-50%)

      .right-arrow
        fill #6001ff
        width 16px
        height 12px
        transform rotate(180deg)

  .more-icon
    display none

.posts-list.grid-view
  .posts-wrap
    & > .row
      margin 0 -10px

  .blogposts-item-wrap
    grid-col(3)
    padding 0 10px
    +breakpoint-down('md')
      flex 0 0 100%
      max-width 100%

  .blogpost-item
    flex-direction column
    box-shadow 3px 11px 6px rgba(96, 1, 255, 0.02)
    border-radius 10px
    background-color #ffffff
    margin 0
    margin-bottom 50px

    &:hover
      .post-txt
        .text-box
          .news-post
            background-color #6001ff
            a
              color #ffffff
          .point-icon
            opacity 1

    .post-image
      max-width 100%
      padding 0
      flex 0 0 100%
      max-height 206px
      overflow hidden
      position relative
      border-top-left-radius 10px
      border-top-right-radius 10px
      &:hover
        &:before
          opacity 0

      &::before
        content ''
        box-shadow 3px 11px 6px rgba(96, 1, 255, 0.22)
        border-radius 9px 9px 0 0
        background-color rgba(96, 1, 255, 0.42)
        top 0
        right 0
        z-index 2
        width 100%
        height 100%
        position absolute
        opacity 1
      &::after
        content ''
        position absolute
        background linear-gradient(to bottom, rgba(0,0,0,0) 2%, #fff 85%)
        width: 100%
        height 100%
        top 0
        right 0
        z-index 100

      img
        width 100%
        position relative
        // background linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%)
        // background linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%)
        // z-index 20


    .post-txt
      max-width 100%
      padding 0
      flex 0 0 100%

      .text-box
        background none
        box-shadow none
        margin 0
        padding 0
        border-radius 0
        padding 16px 18px 1px 10px
        h2
          color #111c4c
          font-size 12px
          font-weight 400

        .news-post
          position absolute
          padding 0 10px
          height 29px
          border-radius 14px
          background-color #f2f3f8
          display inline-block
          text-align center
          bottom 99%
          right 50%
          transform translateX(50%)
          transition all 300ms ease-in-out

          a
            color #6001ff
            font-size 12px
            font-weight 400
            line-height 29px

        .point-icon
          width 44px
          height 44px
          box-shadow 2px 6px 6px rgba(253, 222, 16, 0.48)
          background-color #fdde10
          border-radius 100%
          display inline-block
          line-height 44px
          bottom -25%
          left 50%
          transform translateX(-50%)
          position absolute
          transition all 300ms ease-in-out
          opacity 0
          svg
            margin-right 12px


      .abstract,
      .comment-txt
        display none

    .right-icon
      display none

    .more-icon
      display block

.site-pagination
  text-align center
  margin-bottom 100px

  .site-pagination-item
    margin-top 20px
    height 52px
    box-shadow 3px 11px 6px rgba(96, 1, 255, 0.02)
    border-radius 26px
    background-color #ffffff
    padding 17px 12px
    max-width 100%

    +breakpoint-down('sm')
      padding 16px 0px 16px 10px

    .prev,
    .previous,
    .next
      width 32px
      height 32px
      background-color #f2f3f8
      border-radius 100%
      display inline-block
      line-height 30px

      svg
        width 8px
        height 8px
        fill #6001ff

    .next
      svg
        flip()

    .page-numbers , .current
      color #000000
      font-size 12px
      font-weight 400
      padding 0px
      width 30px
      height 30px
      border-radius 100%
      line-height 30px
      display inline-block
      text-align center

    .current
      background-color #6001ff
      color #fff

      &:hover
        background-color #6001ff
        color #fff


        /**********************************end(blog-edit)*******************/
.page-categorize
  position relative
  .header-weblog
    padding-top 60px
    padding-right 40px
    z-index 1000
    h1
      color #ffffff
      font-size 18px
      font-weight 700
      padding-bottom 5px

    .placeholder-header
      width 8px
      height 10px
      fill #fdde10

    .text-categorize
      color #ffffff
      font-size 12px
      font-weight 300
      a
        color #ffffff
        font-size 12px
        font-weight 300
        padding 0px 5px
      strong
        font-weight 700
        color #ffffff
        font-size 12px
        padding-right 5px
  .header-pic
    max-height 319px
    // margin-top -34px
    overflow hidden
    position absolute
    left -60px
    bottom -39px



    /**********************end(page-categorize)*******************/

.post-single
  margin-top 75px
  margin-bottom 20px
  box-shadow 1px 3px 0 rgba(96, 1, 255, 0.02)
  border-radius 10px
  background-color #fff
  padding 40px 35px

  .title-single
    .post-title
      svg
        width 23px
        height 23px
        stroke #6001ff
        margin-top 5px
        fill rgba(0, 0, 0, 0)
        stroke-width 2px

      .txt-title
        align-self center
        h1
          color #6001ff
          font-size 14px
          font-weight 700
        +breakpoint-only('xs')
          font-size 12px

        span
          color #111c4c
          font-size 11px
          font-weight 300
          padding-right 11px
          align-self center


    .comment-txt
      margin-right 5px
      align-items center
      +breakpoint-down('md')
        margin 20px 0px

      .like-txt ,
      .lookout-txt
        margin-left 40px
        +breakpoint-down('lg')
          margin-left 20px
        +breakpoint-down('md')
          margin-left 33px
        +breakpoint-down('sm')
          margin-left 20px
        +breakpoint-only('xs')
          margin-left 9px

        a
          color #1b2c7a
          font-size 12px
          font-weight 400
        .heart ,
        .message-circle
          width 16px
          height 16px
          stroke #6001ff
          margin-left 5px
          fill rgba(0, 0, 0, 0)

        .message-circle
          stroke #ddc317

      .txts

        font-size 11px
        font-weight 400
        padding-left 10px
        line-height 26px
        height 26px
        border-radius 13px
        background-color #f2f3f8
        display inline-block
        padding 0px 11px
        a
          color #6001ff
        &:hover
          background-color #ddc317
          a
            color #000
          /******************************************/
  .post-content

    p
      color #2e2e2e
      font-size 12px
      font-weight 400
      padding-left 27px
      +breakpoint-down('lg')
        font-size 11px
    h3
      color #6001ff
      font-size 14px
      font-weight 700

    .type-list-2
      li
        &::before
          content url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAz0lEQVQoU3WPz0oCcRSFv2MPYEs3BkoLoXwAI3InQW8gqAO9SW5m58LFLAr6TQ8Qgi6LCOw5pJZtBXd1wmFGpsi7un8+7jlHlOoan37BcUCz8n7bq1gMcONAPNuEFN38C0a4hngBWhaJv4kf0EcZVoQPEUvgpHRYAyuZcI8mmXQkvwIXf6XyeYOJAxor9/YENPfAn5jzLEyE24gFcATMMW8SXcMZUEWku9RD3KmIR5ukSD3CPYk7w/sOzD9fAvWAbgsbI3wlMf0F7vG4tdb/Ad1EO6lEWfZBAAAAAElFTkSuQmCC')

    .type-list-2 , ul

      li
        color #000
        font-size 12px
        font-weight 700

    blockquote
      position relative
      margin-bottom 50px
      &::before
        content url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAARCAYAAADQWvz5AAABkklEQVQ4T+2TsUtVYRjGf8+xHAI1AnG7bQ06NLk4aBBuDuImUfdcQYeItjYRXVyUIByCBu+5QSI4BNUQFNygtaFmEUVCaJIrOah4nzjXew/nHs5N/wDf7Xt53t93vuc8r+Zw3xlMGkSmAvi9jr7G7RAPA0NZTXzugqqK+IlEJU8AHEbWnQZI/gaM5erES4U4RJQ7gGqRdfsKoFfXoEvNbng0jvgMBDmGJ2YXA2/ITHf4aytt2SkGXpN5lhLvR9bd7HAo14DepG8W2kCh/B6YbAkMPypWHMSkHuHemyIGJSUzm4AW8Y09cQD0JyBRrtQ1kx4q4QmLj+le3Yw0QIs42A1Ykplve4aZjtBmq1fC/RZfgPsp3WG3GYhXZFTiE9CT8aJ2Zgrv0FEj2YHfYOKv60rrLF5X6nraMdkyL8potTXUYdeOA3NvHR3kggQf/pqpLXT+H9A5F0/fijUx6DHibXPgCLN6DMtpSHNpq8CDpu4n5nmEvrcu0hy+dQoP6/CnD36toZO80JXwoKEA7ERoO6v5B2GwrcGSGAAcAAAAAElFTkSuQmCC')
        position absolute
        top 20%
        right 0

      &::after
        content url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAABfUlEQVQ4T+2Uv0uVURzGP895K4TAhoYgBAfBJkfBNfsHajCSi3rEcM0loiEoaGgIbI7K91YYbW4u/mhJdx1s0EAQjJa8YyjvE++l9/be994L/gGd7Tzf53zOOc/3cESXMYGTPrj6Af3sVi+0KXw5g0RlU8S3EY+AUSDB3EzRl7KnhvsvBh5jpoHrwEELEoMXMQttO5s7KVoptPt44EysATdKvkYTMoPnJN50HL0NYkWxBYxVfA3l90rEAXCtCpG5tYQ2cn0WT1osd8noUBHfQ3xqK4o6GS9S9K3Qo7wOjJd8v23mLsCqYvBHTK1UPL5kBl+j00Jrhil+AaHQLF7WMz3M54ryDjDSgoh6milWujaG2C5rmRl/jzYLyAlwpWUwT1P0rLxgBt+V+FzWghl6h74XEFfCykM+EmTBzL9F+z269xU4Q+zl16lC/jH/tjjiBcRij9fb+A/pSObcmTxAvOoR7Ilmg58bBqoGw6kynqToxzQeDqH5RSQdvozdP4MdpLITSZr3AAAAAElFTkSuQmCC')
        right 96%
        bottom -6%
        position absolute
      h2
        color #6001ff
        font-size 14px
        font-weight 700

    .sharing
      margin-right 424px
      position relative
      +breakpoint-down('xl')
        margin-right 338px

      +breakpoint-down('lg')
        margin-right 204px

      +breakpoint-down('md')
        margin-right 110px

      +breakpoint-down('sm')
        margin-right 90px
      +breakpoint-only('xs')
        margin 0px auto

      &:before
        content ''
        width 212px
        height 1px
        background-color #6001ff
        opacity 0.04
        position absolute
        right -34%
        top 52%
        +breakpoint-down('xl')
          right -38%
        +breakpoint-down('lg')
          right -46%

        +breakpoint-down('md')
          width 115px
          right -31%

        +breakpoint-down('sm')
          display none
      &:after
        content ''
        width 212px
        height 1px
        background-color #6001ff
        opacity 0.04
        position absolute
        display inline-block
        top 52%
        left 30%
        +breakpoint-down('xl')
          left 20%
        +breakpoint-down('lg')
          left 2%
        +breakpoint-down('md')
          width 115px
          left 5%

        +breakpoint-down('sm')
          display none
      h4
        color #000000
        font-size 11px
        font-weight 300
        padding-right 10px

      .share-icon
        margin-top -3px
        a
          margin-right 12px
          &:hover
            fill #6001ff
          /********************************end(post-single)***************/

.lables-single
  margin-bottom 80px

  svg
    width 12px
    height 11px
    fill #6001ff
    opacity 0.26
    align-self center

  .txt
    margin-right 5px
    color #000000
    font-size 12px
    font-weight 300



    a
      color inherit

      &:hover
        color #6001ff


.offer-single
  margin-bottom 55px
  .title-offer
    display table
    margin 0px auto

    svg
      width 24px
      height 24px
      fill #6001ff
    h2
      color #6001ff
      font-size 14px
      font-weight 700
      margin-right 9px

  .blogposts-item-wrap
    margin-top 40px

    .blogpost-item
      +breakpoint-down('lg')
        padding 5px
      .blogpost-single
        box-shadow 3px 11px 6px rgba(96, 1, 255, 0.02)
        border-radius 10px
        background-color #fff
        +breakpoint-down('md')
          margin-bottom 15px
        &:hover
          .post-img
            .news-post
              background-color #6001ff
              a
                color #fff
          .txt-box
            .point-icon
              opacity 1

          .post-img
            &::before
              opacity 0
            &:after
              opacity 1
        .post-img
          transition all 300ms ease-in-out
          position relative
          margin 0
          .news-post
            position absolute
            padding 0 10px
            height 29px
            border-radius 14px
            background-color #f2f3f8
            display inline-block
            text-align center
            bottom 0
            right 50%
            transform translateX(50%)
            z-index 1000
            transition all 300ms ease-in-out


            a
              color #6001ff
              font-size 12px
              font-weight 400
              line-height 29px
          img
            position relative
            z-index 20
            border-radius 10px
            width 100%

          &::before
            content ''
            position absolute
            box-shadow 3px 11px 6px rgba(96, 1, 255, 0.02)
            border-radius 9px 9px 0 0
            background-color rgba(96, 1, 255, 0.25)
            top 0
            right 0
            z-index 21
            width 100%
            height 91%
            border-radius 10px
          &::after
            content ''
            position absolute
            background linear-gradient(to bottom , rgba(0,0,0,0) -9% , rgba(255,255,255,1) 100%)
            width 100%
            height 100%
            top 2px
            right 0
            z-index 100

      .txt-box
          padding 25px 20px
          position relative

          +breakpoint-down('lg')
            padding 16px 8px 26px 0px
          h2
            color #111c4c
            font-size 12px
            font-weight 400
            +breakpoint-down('xl')
              font-size 10px
          .date
            color #111c4c
            font-size 10px
            font-weight 300


          .point-icon
            width 34px
            height 34px
            box-shadow 2px 6px 6px rgba(253, 222, 16, 0.48)
            background-color #fdde10
            border-radius 100%
            display inline-block
            line-height 34px
            bottom -21%
            right 50%
            transform translateX(50%)
            position absolute
            transition all 300ms ease-in-out
            opacity 0
            svg
              margin-right 8px
            +breakpoint-down('sm')
              right 47%
              /*************************end(offer-single)*********/
.user-sight
  box-shadow 1px 3px 0 rgba(96, 1, 255, 0.02)
  border-radius 10px
  background-color #ffffff
  padding 41px 45px 7px 52px
  margin-bottom 100px

  +breakpoint-down('md')
    padding 41px 15px 7px 15px


  .user-title
    margin-bottom 50px
    .user
      width 18px
      height 18px
      fill #6001ff
      opacity 0.18
      margin-right -4px

    .comment
      width 24px
      height 18px
      fill #6001ff
      margin-top -10px
    h3
      color #6001ff
      font-size 14px
      font-weight 700
      padding-right 16px

  .user-content
    .right-user
      .name-sight , .email-sight
        position relative
        svg
          width 12px
          height 14px
          fill #6001ff
          position absolute
          top 50%
          transform translateY(-50%)
          right 24px
          z-index 1000


        .mail
          top 50%
          right 24px
          transform translateY(-50%)
        input
          width 100%
          height 47px
          border-radius 24px
          background-color #f9f9f9
          border 0
          padding-right 45px
          color #6001ff
          font-size 12px
          font-weight 300
          margin-bottom 18px

    .left-user
      position relative
      padding-right 20px

      textarea
        width 100%
        height 114px
        border-radius 22px
        background-color #f9f9f9
        border 0
        padding 30px
        color #6001ff
        font-size 12px
        font-weight 300
        margin-bottom 6px
        resize vertical
        +breakpoint-down('xl')
          margin-right auto
        +breakpoint-down('lg')
          margin-bottom 20px


      .send-sight
        margin-right auto
        display table
        height 39px
        box-shadow 1px 4px 6px rgba(253, 222, 16, 0.16)
        border-radius 20px
        background-color #fdde10
        border none
        padding 11px 17px
        line-height 15px
        color #000
        transition all 300ms ease-in-out
        &:hover
          background-color #6001ff
          box-shadow 0 2px 13px rgba(96,1,255,0.15)
          color #fff
          svg
            fill #fff
        +breakpoint-down('lg')
          right 79%
        +breakpoint-down('md')
          right 68%
        +breakpoint-down('sm')
          right 61%
        svg
          margin-right 9px
          margin-top -8px





            /*******************end(user-content)*******************/

  .comments-list
    margin-top 70px


    .comment
      .back-comment1, .back-comment2
        border-radius 24px
        border 1px solid #d9dee0
        padding 19px 26px 9px 12px
        margin-bottom 20px
        position relative

        .comment-avatar

        .comment-body

          h3
            color #6001ff
            font-size 13px
            font-weight 700
            padding 0px 18px
            margin-bottom 4px

            +breakpoint-down('md')
              padding 0px


          p
            color #2e2e2e
            font-size 12px
            font-weight 400
            padding 1px 14px 14px 0px
            +breakpoint-down('md')
              font-size 11px
            +breakpoint-down('sm')
              padding 0px

        // +breakpoint-down('md')
        //   flex auto
        //   max-width 100%

        .answer-comment
          a
              color #000000
              font-size 12px
              font-weight 400
              position absolute
              left 3%
              background-color #fff
              padding 0px 17px
              bottom -7px
            // +breakpoint-down('xl')
            //   left 17%
            // +breakpoint-down('xl')
            //   left 23%
            svg
              width 14px
              height 14px
              fill #6001ff
      .children
        padding-right 50px
        .back-comment3
          border-radius 24px
          border 1px solid #d9dee0
          padding 20px 26px 17px 12px
          margin-bottom 30px
          position relative
          .comment-avatar

          .comment-body
            h3
              color #6001ff
              font-size 13px
              font-weight 700
              padding 0px 10px
              +breakpoint-down('sm')
                padding 0px
            p
              color #2e2e2e
              font-size 12px
              font-weight 400
              padding 5px 9px 14px 0
              +breakpoint-down('md')
                font-size 11px
              +breakpoint-down('sm')
                padding 0px

          .answer-comment
            a
              color #000000
              font-size 12px
              font-weight 400
              position absolute
              left 3%
              bottom -7px
              background-color #fff
              padding: 0px 17px

              svg
                width 14px
                height 14px
                fill #6001ff


        /*****************************end(single-page)*****************************/
.page-categorize
  .header-pic2
    // max-height 373px
    max-height 314px
    // margin-top -34px
    overflow hidden
    position absolute
    bottom -41px
    left -87px
    +breakpoint-down('md')
      margin-top -46px
      max-height 268px
      left -28%

.post-wrap

  .post-content
    box-shadow 1px 3px 0 rgba(96, 1, 255, 0.02)
    border-radius 10px
    background-color #ffffff
    padding 40px 55px
    margin-top 75px
    margin-bottom 50px

    +breakpoint-down('md')
      padding 40px 15px

    .location-pic
      position relative
      box-shadow 1px 3px 0 rgba(208, 206, 212, 0.02)
      background-color #fff
      width 532px
      max-width 100%

      #map_location
        min-height 240px

      .place
        position absolute
        right 8%
        top 32%

        svg
          width 19px
          height 27px
          fill #6001ff

    .text-content
      padding 50px 65px 0px 0px
      flex 1

      +breakpoint-down('md')
        padding 50px 12px 0px 0px



      .phone-number
        margin-bottom 30px

        +breakpoint-down('lg')
          display table
          margin 0px auto



        .phone
          h3
            color #000000
            font-size 18px
            font-weight 300
            direction ltr
            text-align left
            font-family "yekan"
            line-height 15px
            padding-left 10px
            // +breadpoint-down('380px')
            //   padding-left 0px
            //   font-size 13px

            strong
              color #6001ff
        .tell-icon
          svg
            width 20px
            height 25px
            fill #6001ff

            +breakpoint-down('lg')
              display none


      .address-site
        .text
          h3
            color #000000
            font-size 12px
            font-weight 400
            padding-left 10px
            font-family "yekan"

        +breakpoint-down('lg')
          display table
          margin 0px auto

        .place
          svg
            width 14px
            height 18px
            fill #6001ff
            margin-top 13px

            +breakpoint-down('lg')
              display none

    .pic-girl
      padding-right 60px

    .content-form
      width 100%
      margin-top 49px
      margin-bottom 100px
      .top-user

        margin-bottom 20px
        .name-sight , .email-sight , .object-sight
          input
            width 100%
            height 48px
            border-radius 24px
            background-color #f9f9f9
            border 0
            color #6001ff
            font-size 12px
            font-weight 300
            padding-right 48px
            font-family 'yekan'
            position relative
            +breakpoint-down('md')
              margin-bottom 20px
          svg
            width 12px
            height 13px
            fill #6001ff
            position absolute
            z-index 1000
            transform translateY(-50%)
            right 38px
            top 50%

            +breakpoint-down('md')
              right 38px
              top 30%


      .bottom-user
        position relative
        textarea
          width 100%
          height 105px
          border-radius 29px
          background-color #f9f9f9
          color #6001ff
          font-family "yekan"
          font-size 12px
          font-weight 300
          border 0
          resize vertical
          padding 17px 33px


        .send-tick
          position absolute
          height 39px
          box-shadow 1px 4px 6px rgba(253, 222, 16, 0.16)
          border-radius 20px
          background-color #fdde10
          bottom -46%
          left 1%
          border 0
          padding 11px 18px
          line-height 14px
          color #000000
          font-size 12px
          font-weight 400
          transition all 200ms ease-in-out

          svg
            margin-right 17px
          &:hover
            background-color #6001ff
            box-shadow 0 2px 13px rgba(96,1,255,0.15)
            color #fff
            svg
              fill #fff
    .about-item
      margin-bottom 90px
      .pic-box
        position relative
        margin-top 49px
        padding-right 60px
        .pic1
          position relative
          z-index 1000
          img
            padding-right 40px
        .pic2
          img
            z-index 1000
            bottom -22%
            right 97%
            position absolute
            z-index 1000

          .icon3
            position absolute
            z-index 0
            bottom -66%
            right 33%

          .icon2
            position absolute
            z-index 1
            bottom -48%
            right 63%
          .icon1
            position absolute
            top -55%
            right 66%
    .about-us
      flex 1
      padding 10px 144px 0px 38px
      +breakpoint-down('md')
        padding 10px 16px 0 16px
      h2
        color #6001ff
        font-size 18px
        font-weight 700

        +breakpoint-down('md')
          text-align center
      p
        color #2e2e2e
        font-size 12px
        font-weight 400
    h3
      color #6001ff
      font-size 14px
      font-weight 700

      +breakpoint-down('md')
        text-align center
    p
      color #2e2e2e
      font-size 12px
      font-weight 400
      margin-top -10px

    .post-list1

      img
        margin-right auto
      /************************end(post-content)**********************/

  .title-question
    margin-bottom 40px
    h3
      text-align center
      color #6001ff
      font-size 16px
      font-weight 700
      position relative
      margin-bottom 80px
      &::after
        content ''
        width 40px
        height 2px
        border-radius 1px
        background-color #4f03cf
        opacity 0.08
        position absolute
        bottom -83%
        left 48%
        display inline-block

  .observe-ticket,
  .long-delivery
    box-shadow 1px 3px 0 rgba(96, 1, 255, 0.02)
    border-radius 29px
    background-color #ffffff
    padding 13px 45px
    margin-top 20px
    margin-bottom 30px

    .position-site
      .position
        position relative
        width 30px
        height 30px
        background-color #f6f4f9
        border-radius 100%
        color #6001ff
        font-size 17px
        line-height 30px
        text-align center
      .txt
          h4
            color #6001ff
            font-size 14px
            font-weight 400
            padding-top 5px

    .choice-observe

      a
        position absolute
        left 10%
        color #010101
        font-size 12px
        font-weight 300
        padding-top 5px
        &:hover
          color #6001ff
        +breakpoint-down('xl')
          left 13%
        +breakpoint-down('lg')
          left 17%
    .negative-site
      border-bottom 1px solid #f4f9fa
      width 100%
      padding 10px 0px
      margin-bottom 26px

      .negative
        position relative
        width 30px
        height 30px
        background-color rgba(253 , 222, 16, 0.18)
        border-radius 100%
        color #fdde10
        font-size 25px
        line-height 30px
        text-align center

      .txt
        h4
          color #6001ff
          font-size 14px
          font-weight 400
          padding-top 5px
    .content-service
      h4
        color #000000
        font-size 14px
        font-weight 700
      p
        color #2e2e2e
        font-size 12px
        font-weight 400
        padding 9px 0px 16px 165px


.accordion
  box-shadow 1px 3px 0 rgba(96, 1, 255, 0.02)
  border-radius 29px
  background-color #ffffff
  padding 0 35px !important
  margin-top 20px
  margin-bottom 30px

  .accordion-head
    border-bottom 1px solid #faf7ff
    padding 14px 20px 14px 35px
    margin 0 -35px
    align-items center
    cursor pointer


    &:before
      content '+'
      circle(30px, true)
      background-color #f6f4f9
      color #6001ff
      font-size 18px
      font-weight 500
      margin-left 12px

    +breakpoint-down('md')
      padding 14px 12px 14px 3px

    .accordion-title
      color #6001ff
      font-size 14px
      font-weight 400

    .open-accordion

      margin-right auto
      font-size 12px
      font-weight 300

      a
        color alpha(#010101, 53%)
        &:hover
          color alpha(#6001ff, 53%)

  .accordion-body
    padding 25px 0 35px
    color #2e2e2e
    font-size 12px
    font-weight 400
    line-height 25.01px
    display none
    text-align justify

    h2,
    h3,
    h4,
    h5,
    h6
      color #000000
      font-size 14px
      font-weight 700
      margin-bottom 10px

  &.opened
    .accordion-head
      &:before
        content '-'
        background-color alpha(#fdde10, 18%)
        color #fdde10

      .open-accordion
        display none

    .accordion-body
      display block
      /********************************************end(contact-us)******************************/





.service-title
  margin-top 170px
  .title-virtual-server
    text-align center

    h2
      color #6001ff
      font-size  18px
      font-weight 700
    p
      color #000000
      font-size 12px
      font-weight 300
      margin-bottom 74px

  .private-server
    padding-right 120px
    margin-bottom 150px

    +breakpoint-down('lg')
      padding-right 0px

    +breakpoint-down('md')
      margin-bottom 70px

    .special-server
      box-shadow 3px 11px 6px rgba(96, 1, 255, 0.02)
      border-radius 10px
      background-color #ffffff

      +breakpoint-down('md')
        margin-bottom 40px
        padding-bottom 10px

      .right-special
        position relative

        +breakpoint-down('lg')
          width 100%
          background-color #6001ff
          max-width 100%
          height 140px
          border-top-right-radius 10px
          border-top-left-radius 10px
          margin-bottom 50px


        .back4
          position absolute
          width 100%
          height 100%
          top 0
          right 0
          z-index 0
          border-radius 10px
          border-top-left-radius 0
          border-bottom-left-radius 0

          +breakpoint-down('lg')
            display none

        .txt-right
          position relative
          padding 52px 40px 52px 70px
          z-index 1000
          text-align center

          +breakpoint-down('lg')
            margin 0px auto
            padding 0



          svg
            width 38px
            height 38px
            fill #fff

          h4
            color #fff
            font-size 12px
            font-weight 400
            padding 12px 0px 35px 0px

            +breakpoint-down('lg')
              padding 12px 0px 4px 0px


          .price-server
            color #ffffff
            font-size 17px
            font-weight 700


          p
            color #fdde10
            font-size 15px
            font-weight 300
            padding-bottom 12px


      .left-special
        padding-left 0px

        +breakpoint-down('lg')
          padding-right 20px
          margin-top -50px
        ul
          padding 16px 0px 17px 0px
          li
            color #000000
            font-size 12px
            font-weight 400
            line-height 26px
            svg
              width 10px
              height 10px
              fill #6001ff
              margin-left 5px
        .space-service , .band-service
          padding 9px 16px 0px 0px


          .space
            color #000
            font-size 12px
            font-weight 400
            width 100px
          .value
            p
              font-size 12px
              font-weight 400
              color #6001ff

        .band-service
          .space
            padding-left 57px
            /******************************end(special-server)****************/

    .officer-service
      padding-right 51px
      position relative

      +breakpoint-down('md')
        padding-right 0px

      &:before
        content ''
        position absolute
        size(100%)
        top 0
        right 0
        -webkit-clip-path url(#virtual_background2)
        clip-path url(#virtual_background2)
        min-height 1100px
        z-index -1



      h2
        color #6001ff
        font-size 16px
        font-weight 700
        +breakpoint-down('md')
          text-align center

        p
          color #000000
          font-size 12px
          font-weight 400
          padding-top 5px

          +breakpoint-down('md')
            text-align center


      p
        padding-left 62px
        padding-bottom 33px

        +breakpoint-down('md')
          padding-left 0px
          text-align justify

      .order-service
        border 0
        margin 30px 193px
        height 38px
        border-radius 19px
        background-color #fdde10
        padding 11px 33px
        font-size 12px
        font-weight 400
        color #000
        transition all 300ms ease-in-out
        svg
          width 16px
          height 16px
          fill #000
          margin-left 9px
        &:hover
          box-shadow 0 2px 13px rgba(96, 1, 255, 0.15)
          background-color #6001ff
          color #fff
          svg
            fill #fff

        +breakpoint-down('md')
          margin 0px auto
          display table

          /****************************************end(virtual-server)*********************/


.security
  .back-security

    .item-virtual
      margin-top 0px
      margin-bottom 120px
      &:before ,
      &:after
        display none

      .point-virtual
        width 25px
        height 5px
        position absolute
        top 58%
        right 46%
        margin-top 145px
        text-align center

        +breakpoint-down('md')
          display none

    .left-security
      .point-virtual
        margin-top 160px

        /*********************************end(security)**********************/
.plans-site
  margin-bottom 50px
  .title-plans
    h3
      text-align center
      color #6001ff
      font-size 16px
      font-weight 700
      position relative
      margin-bottom 70px
      &:before
        content ''
        width 40px
        height 2px
        border-radius 1px
        background-color alpha(#4f03cf 8%)
        position absolute
        bottom -50%
        right 50%
        transform translatex(50%)

  .plans-item
    margin-right 60px

    +breakpoint-down('sm')
      margin-right 0px



    .object-plans
      text-align right
      padding-top 109px

      ul

        li
          color #6001ff
          font-size 12px
          font-weight 400
          line-height 39px
          text-align right

    .general-server
      box-shadow 0 2px 6px rgba(96, 1, 255, 0.03)
      border-radius 10px
      background-color #ffffff
      margin-right 20px
      transition all 300ms ease-in-out
      padding-bottom 20px
      overflow hidden
      &:hover
        box-shadow 0 2px 70px rgba(96, 1, 255, 0.15)
        transform translateY(-10px)
        .title-server
          .back5
            fill #f9f5ff
          .text-title
            h2
              color #6001ff
            h3
              color #000

      +breakpoint-down('md')
        margin-bottom 25px
        width 44%
        display table


      +breakpoint-down('lg')
        margin 30px auto



      +breakpoint-down('sm')

        width 100%


        .type-object
          .order-service
            background-color #fdde10
            color #000
            svg
              fill #000



      .title-server
        position relative
        padding 27px 25px 36px 6px

        +breakpoint-down('md')
          background-color #6001ff
          width 100%
          height 80px

        .back5
          position absolute
          width 100%
          height 100%
          top 0
          right 0
          z-index 0
          border-radius 10px
          border-top-left-radius 0
          border-bottom-left-radius 0
          fill#6001ff
          transition all 300ms ease-in-out

          +breakpoint-down('md')
            display none

        .text-title
          position relative
          z-index 1000
          text-align center
          margin-left 7px

          h2
            color #fff
            font-size 14px
            font-weight 700
          h3
            color #fdde10
            font-size 12px
            font-weight 400

      .type-object
        text-align center
        ul
          padding-bottom 20px

          +breakpoint-down('md')
            padding-top 20px
          li
            line-height 39px
            font-family font-stack('Arial')
            font-weight 400
            font-size 12px
            position relative

            &.fa
              font-family yekan-font-family
              direction rtl

            span
              color #6001ff
              font-size 12px
              font-weight 700


            &::after
              content ''
              width 40px
              height 1px
              border-radius 1px
              background-color alpha(#4f03cf 8%)
              position absolute
              bottom -1%
              left 29%
              transform translatex(50%)
              +breakpoint-only('xs')
                left 36% !important

            &:last-child
              &:after
                content none


        .order-service
          border 0
          height 38px
          border-radius 19px
          background-color #6001ff
          padding 0px 33px
          font-size 12px
          font-weight 400
          color #fff
          transition all 300ms ease-in-out
          line-height 38px
          display inline-block

          svg
            width 16px
            height 16px
            fill #fff
            margin-left 9px
            /**************************end(plans-site)***************************/

.explain-service
  padding-right 95px

  +breakpoint-down('md')
    padding-right 15px

  h2
    color #6001ff
    font-size 14px
    font-weight 700
    padding-bottom 9px

    +breakpoint-down('md')
      text-align center
  .post-content
    p
      color #2e2e2e
      font-size 12px
      font-weight 400
      margin-bottom 40px
      padding-left 185px

      +breakpoint-down('md')
        padding-left 0px
        text-align justify
    ul
      margin-bottom 65px

      +breakpoint-down('sm')
        display table
        margin 0px auto 65px auto


      li
        color #000000
        font-size 12px
        font-weight 400
        line-height 26px
        padding-right 20px
        &::before
          top 2%
          left 2%
        svg
          width 10px
          height 10px
          fill #6001ff
          margin-left 5px


  .accordion-group2
    padding-left 120px

    +breakpoint-down('md')
      padding-left 0px






