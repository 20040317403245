/**
 * Defined animations
 */
@keyframes headerImageMove
  0%
    transform translateY(0px) scale(1)

  25%
    transform translateY(-3px) scale(0.995)

  50%
    transform translateY(0px) scale(1)

  75%
    transform translateY(3px) scale(1.005)

  100%
    transform translateY(0px) scale(1)

@keyframes fillSecurityLines1
  0%
    transform-origin right

  25%
    transform scaleX(1)

  25.001%
    transform-origin left

  50%
    transform scaleX(0.5)
    transform-origin left

  75%
    transform scaleX(0.5)
    transform-origin left

  100%
    transform scaleX(0)
    transform-origin left

// @keyframes fillSecurityLines
//   0%
//     transform scaleX(0)

//   50%
//     transform scaleX(1)

//   100%
//     transform scaleX(0)