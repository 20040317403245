// Font icons and Css Sprite Icons
// font icons generated by icomoon.io app
// CSS Sprites generated by zeroSprites
svg.icon
  line-height 1
  vertical-align middle
  display inline-block
  width 1em
  height 1em
  transition 200ms all ease-in-out

  path
    transition inherit