/*
 * Headers styles
**/
.site-header
  padding 30px 15px
  align-items center
  z-index 1000

  .container
    justify-content space-between
    align-items center
    position relative

  .site-logo
    color #fdde10
    font-size 11px
    font-weight 400
    position relative
    align-self center
    z-index 1000

    a
      color inherit

    span
      position absolute
      bottom 4px
      left 0

  .top-nav
    align-self center
    position relative
    z-index 1000

    & > ul
      grid-row()

      .menu-item
        padding 0 25px

        & > a
          color #fff
          font-size 14px
          font-weight 400
          position relative
          display block
          white-space nowrap
          height 28px

          &:after
            content ''
            position absolute
            width 100%
            height 2px
            border-radius 1px
            background-color #fff
            top 27px
            right 0
            transition-duration 0.10s
            transform-origin center
            transform scaleX(0)

          &:hover
            &:after
              transform scaleX(1)

          +breakpoint-down('xl')
            padding 0px 10px


          svg
            size(9px)
            fill #000
            transform rotate(-90deg)
            margin 2px 8px 0 0

        // will be changed

        .sub-menu
          position absolute
          top 60px
          right -83px
          z-index 100
          display flex
          flex-wrap nowrap
          background-color #fff
          box-shadow 0 0 65px rgba(0, 0, 0, 0.1)
          border-radius 18px
          overflow hidden
          transition all 250ms ease-in-out


          & > li
            position relative
            width 100%
            min-height 1px
            // flex 0 0 20%
            // max-width 20%
            margin 40px 50px 0 34px


            & > a
              color #6001ff
              font-size 14px
              font-weight 700
              white-space nowrap

            & > ul
              padding-right 8px
              padding-top 8px


              & > li

                &:before
                  content ''
                  display inline-block
                  width 4px
                  height 4px
                  background-color  #f3ebff
                  transform translatex(11px)
                  transition 200ms all ease-in-out
                  border-radius 100%

                a
                  color #2a2b3d
                  font-size 12px
                  font-weight 400
                  line-height 37px
                  transition all 200ms ease-in-out


                &:hover
                  a
                    color #6001ff

                  &:before
                    background-color #6001ff


          .last-sub
            margin 0

            .left-sub-menu

              .sub-back2
                position absolute
                top 0px
                left -96px
                z-index 0
                border-radius 10px
                border-bottom-right-radius 0
                border-bottom-left-radius 0
                transition all 200ms ease-in-out
                height 287px
                width 416px
                transform scale(1.08)

              .sub-back
                  position relative
                  z-index -1
                  height 287px
                  right 4px
                  top 5px
                  transform rotate(-3deg)


              .sub-content
                position absolute
                top 20px
                left 50px
                text-align center

                .icn
                  width 50px
                  height 50px
                  background-color #5e01f9
                  border-radius 100%
                  display inline-block
                  line-height 50px
                  line-height 50px
                  text-align center

                  svg
                    width 26px
                    height 26px
                    fill #fdde10

                h2
                  color #6206fd
                  font-size 14px
                  font-weight 700
                  padding-bottom 15px

                span
                  color #5e01f9
                  font-size 19px
                  font-weight 700

                h4
                  color #2b2b2b
                  font-size 15px
                  font-weight 300
                  padding-bottom 40px

                .order-service
                  border 0
                  border-radius 19px
                  background-color #fdde10
                  padding 11px 30px
                  font-size 12px
                  font-weight 400
                  color #000
                  transition all 300ms ease-in-out

                  svg
                    width 16px
                    height 16px
                    fill #000
                    margin-left 9px

                  &:hover
                    box-shadow 0 2px 13px rgba(96, 1, 255, 0.15)
                    background-color #6001ff
                    color #fff

                    svg
                      fill #fff


        &:not(:hover)
          ul
            visibility hidden
            opacity 0
            transform translateY(10px)

      .menu-item.children
        position static


        a
          position relative

          &:before
            content ''
            position absolute
            width 0
            height 0
            border-style solid
            border-width 0 6.5px 8px 6.5px
            border-color transparent transparent #fff transparent
            bottom -42px
            right 50%
            z-index 1000
            opacity 0
            transition all 250ms ease-in-out

        &:hover
          & > a
            &:before
                opacity 1
                transform translateY(-10px)

            svg
              fill #fdde10


  .support-phone
    text-align center
    color #ffffff
    font-size 12px
    font-weight 300
    position relative
    z-index 1000

    svg
      size(20px)
      fill #fdde10
      margin-bottom 10px

    .number
      direction ltr
      text-align left
      font-size 18px

      strong
        color #fdde10

  .user-panel
    position relative
    overflow hidden
    padding 0 17px
    color #6001ff
    font-size 12px
    font-weight 700
    height 40px
    line-height 40px
    background-color #fff
    align-self center
    transition all 200ms ease-in-out
    position relative
    border-radius 22px
    z-index 100

    &:before
      content ''
      background-color #fdde10
      position absolute
      width 200px
      height 400%
      display inline-block
      border-radius 60px
      transform translate(68%, 54%)
      top -41px
      transition all 200ms ease-in-out
      right -38px

    span
      z-index 3
      position relative


    &:hover
      color #000

      svg
        fill #000

      &:before
        transform translate(0)

    //   background-color #fdde10
    //   box-shadow 1px 4px 6px rgba(253,222,16,0.16)
    //   color #000
    //   svg
    //     fill #000


    +breakpoint-down('xl')
      padding 0 15px

    svg
      size(14px)
      fill #6001ff
      margin-left 4px


  .responsive-menu-toggle
    svg
      size(22px)
      fill #fff