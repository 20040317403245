/**
 * Typography
 */
.typography,
.post-content
  font 400 rem(13px)/2.3 yekan-font-family
  color #364c4b
  text-align justify

  p
    line-height inherit
    margin-bottom 27px

    &:last-child
      margin-bottom 0

  .row
    margin-bottom 27px

  a
    color #0f8881
    font-weight 700

    &:hover
      color lighten(#3f3688, 50%)

    &.gray
      color #8a8a8a
      font-style italic

  img.aligncenter
    display block
    margin 0 auto 27px

  img.alignright
    margin-left 1em
    float right

  img.alignleft
    margin-right 1em
    float left

  img.alignleft + p + p,
  img.alignright + p + p
    clear both

  hr
    outline none
    border 0
    border-top 1px solid #eee
    margin-bottom 27px

  h1,
  h2,
  h3,
  h4,
  h5,
  h6
    line-height 1.94
    margin-bottom 10px
    font-weight 500
    color #0eada4

  h1
    font-size rem(20px)

  h2
    font-size rem(18px)

  h3
    font-size rem(16px)

  h4,
  h5,
  h6
    font-size rem(14px)

  ol,
  ul
    list-style none
    margin-bottom 27px
    line-height 2.58
    color #646464

    ul
    ol
      padding-left 20px

      html[dir="rtl"] &
      body.rtl &
        padding 0 50px 0

  ol
    counter-reset item

    li
      &:before
        content counter(item) ". "
        counter-increment item
        display inline-block
        margin-left 15px

  ul
    li
      position relative
      padding-right 30px

      &:before
        content url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAICAYAAAAvOAWIAAAAoklEQVQoU42QMQtBYRSGnxeTwWY1mf0D5UfYyL1SymI2+wEWZZC67qAMJvlJRoMZefWpr+4gnPGc5znn7Yg/KsEjQV2/2CGeWCxlel/hCNokOdpphJtluK7RpXglwVOJRQTDTGnJGaZdMZ0NOodmimeIOWawRfu4RGNcvYuToRGEB/QDaNPN0bF47Z25ILSA2ifwHSOaQbiVWOnJIUOnT196AdHaO5c8xfiaAAAAAElFTkSuQmCC')
        display inline-block
        color #60c339
        font-size rem(21px)
        vertical-align middle
        margin-left 15px
        position absolute
        top -14px
        right 0

  ins
    background-color #FFF8DC
    color darken(#FFF8DC, 70%)
    padding 0 3px


  strong
    font-weight 700

  em
    font-style italic

  pre
    margin-bottom 20px

  blockquote
    quotes none
    margin-bottom 27px
    padding 10px 25px
    position relative
    border-radius 9px
    color #2c6f0f
    font-size 17px
    display table
    width auto

    h1,
    h2,
    h3,
    h4,
    h5,
    h6
      color #ff6430
      font-size rem(14px)
      font-weight 700
      font-style normal

  blockquote p
    margin 0

  & > *:last-child
    margin-bottom 0px

  figure
    margin 0 0 20px
    max-width 100%

    &.aligncenter
      margin 0 auto 20px
      text-align center

      img
        display inline-block

    &.alignright
      float right
      margin-left 1em

    &.alignleft
      float left
      margin-right 1em

    &.alignnone
      width auto !important
      margin 0 -20px 20px
      max-width none

    .wp-caption-text
      color #aaa
      font-size rem(14px)
      font-weight 400
      font-style italic
      padding 10px 10px 0

  img
    &.full-size
      width 100%
      height auto

  .accordion
    .accordion-title
      background-color #60c339
      padding 5px 12px
      border-radius 8px
      color #fff
      font-size rem(13px)
      font-weight 500
      display table
      cursor pointer

    .accordion-content
      margin-bottom 27px
      text-align justify

  .trailer-vid
    margin-bottom 20px

div.wpcf7 .ajax-loader
  position absolute