@import 'fonts'
@import 'icons'

::selection
  background #ABA870
  color #fff

::-moz-selection
  background #ABA870
  color #fff
html
  font-size base-font-size
  min-height 100%

body
  font-family base-font-family
  font-size base-font-size
  color base-text-color
  background-color base-background-color
  direction rtl
  text-align right
  font-weight 400

a,
ins
  text-decoration none

img
  backface-visibility hidden