// Grid system
//
// @author Am!n <ivahid.com>
// @copyright 2017 ivahid.com
//
.container
.container-fluid
  width 100%
  margin-left auto
  margin-right auto
  padding-right ( grid-gutter-width / 2 )
  padding-left ( grid-gutter-width / 2 )

.col
[class^="col-"]
[class*=" col-"]
  position relative
  width 100%
  min-height 1px
  padding-right ( grid-gutter-width / 2 )
  padding-left ( grid-gutter-width / 2 )

  /.no-gutters &,
  /.grid-row &
    padding-left 0
    padding-right 0

.grid-row
.row
  grid-row()

.row
  margin-right ( grid-gutter-width / -2 )
  margin-left ( grid-gutter-width / -2 )

  &.mar24
    margin-right -12px
    margin-left -12px

    [class*="col-"],
    [class^="col-"]
      padding-right 12px
      padding-left 12px

  &.mar20
    margin-right -10px
    margin-left -10px

    [class*="col-"],
    [class^="col-"]
      padding-right 10px
      padding-left 10px

  &.mar15
    margin-right -7.5px
    margin-left -7.5px

    [class*="col-"],
    [class^="col-"]
      padding-right 7.5px
      padding-left 7.5px

  &.mar12
    margin-right -6px
    margin-left -6px

    .col,
    [class*="col-"],
    [class^="col-"]
      padding-right 6px
      padding-left 6px

.grid-top
  align-items flex-start

.grid-bottom
  align-items flex-end

.grid-center
  align-items center

.grid-justify-enter
  justify-content center

.grid-col-top
  align-self flex-start

.grid-col-bottom
  align-self flex-end

.grid-col--center
  align-self center

.grid-col-auto-size
  flex none

.no-gutters
  margin-left 0
  margin-right 0

:root[dir=rtl] [class^="offset-"]
:root[dir=rtl] [class*=" offset-"]
  margin-left 0

// Make grid classes
for key, val in grid-breakpoints
  if( key is not 'xs' )
    @media( min-width: val )
      .container
        max-width: grid-containers[key]

      make-grid-columns(key)
  else
    make-grid-columns(key)
