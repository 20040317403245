@font-face
  font-family 'yekan'
  font-style normal
  font-weight 400
  src url('../fonts/yekan/iranyekanwebregularfanum.eot?nqj53a#')
  src url('../fonts/yekan/iranyekanwebregularfanum.eot?nqj53a#iefix') format("embedded-opentype"),

       url('../fonts/yekan/iranyekanwebregularfanum.woff?nqj53a') format('woff')

@font-face
  font-family 'yekan'
  font-style normal
  font-weight 700
  src url('../fonts/yekan/iranyekanwebboldfanum.eot?nqj53a#')
  src url('../fonts/yekan/iranyekanwebboldfanum.eot?nqj53a#iefix') format('embedded-opentype'),

       url('../fonts/yekan/iranyekanwebboldfanum.woff?nqj53a') format('woff')

@font-face
  font-family 'yekan'
  font-style normal
  font-weight 500
  src url('../fonts/yekan/iranyekanwebmediumfanum.eot?nqj53a#')
  src url('../fonts/yekan/iranyekanwebmediumfanum.eot?nqj53a#iefix') format('embedded-opentype'),

       url('../fonts/yekan/iranyekanwebmediumfanum.woff?nqj53a') format('woff')


@font-face
  font-family 'yekan'
  font-style normal
  font-weight 300
  src url('../fonts/yekan/iranyekanweblightfanum.eot?nqj53a#')
  src url('../fonts/yekan/iranyekanweblightfanum.eot?nqj53a#iefix') format('embedded-opentype'),

       url('../fonts/yekan/iranyekanweblightfanum.woff?nqj53a') format('woff')

@font-face
  font-family 'yekan'
  font-style normal
  font-weight 200
  src url('../fonts/yekan/iranyekanwebthinfanum.eot?nqj53a#'),
  src url('../fonts/yekan/iranyekanwebthinfanum.eot?nqj53a#iefix') format('embedded-opentype'),

       url('../fonts/yekan/iranyekanwebthinfanum.woff?nqj53a') format('woff')