.background-404
    background-color #6001ff
    .container
        .main-page
            padding-bottom 150px
            .right-pic
                margin 0px
                padding-top 190px

            .left-items
                padding-top 190px
                
                .left-pic
                    margin 0px
                    padding-bottom 40px
            
                .return
                    color #6001ff
                    font-size 14px
                    font-weight 400
                    box-shadow 3px 11px 6px rgba(96, 1, 255, 0.02)
                    border-radius 25px;
                    background-color #ffffff
                    padding 11px 23px
                    margin-right 65px

        .logo-page
            position relative

            .site-logo
                position relative
                color #fdde10
                font-size 11px
                font-weight 400
                position relative
                align-self center
                z-index 1000
                text-align center
                span
                    position absolute
                    bottom 4px
                    left 40%


