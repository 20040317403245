// Responsive utils
//
// @author Am!n <ivahid.com>
// @copyright 2017 ivahid.com
//
visibility-selectors()
  for key, val in grid-breakpoints
    .visible-{key}
    .visible-{key}-block
    .visible-{key}-inline
    .visible-{key}-inline-block
    .visible-{key}-table
    .visible-{key}-table-row
    .visible-{key}-table-cell
    .visible-{key}-flex
    .visible-{key}-inline-flex
      {block}

make-visibility-classes(breakpoint)
  .visible-{breakpoint}
    display block !important

  .visible-{breakpoint}-inline
    display inline !important

  .visible-{breakpoint}-inline-block
    display inline-block !important

  .visible-{breakpoint}-flex
    display flex !important

  .visible-{breakpoint}-inline-flex
    display inline-flex !important

  table.visible-{breakpoint},
  .visible-{breakpoint}-table
    display table !important

  tr.visible-{breakpoint},
  .visible-{breakpoint}-table-row
    display table-row !important

  td.visible-{breakpoint},
  th.visible-{breakpoint},
  .visible-{breakpoint}-table-cell
    display table-cell !important

// hide all
+visibility-selectors()
  display none !important

// Visible by breakpoints
for key, val in grid-breakpoints
  +breakpoint-only(key)
    make-visibility-classes(key)

    .hidden-{key}
      display none !important