circle( size = '', cntr = false )
  if( size )
    width size
    height size

  display inline-block
  border-radius 100%

  if( cntr )
    line-height size
    text-align center

triangle( width = 10px, height = 10px, position = 'down', color = #000 )
  width 0
  height 0
  border-style solid
  border-width 5px 10px 5px 0
  border-color transparent #ededed transparent transparent