/*
 * Main styles
*
* Including common elements styles + Main page elements styles
*/
.site-footer
  background-color #f2f3f8

  .main-footer
    position relative


    .icon-top
      width 60px
      height 60px
      box-shadow 2px 9px 16px rgba(96, 1, 255, 0.21)
      background-color #6001ff
      border-radius 100%
      line-height 60px
      position absolute
      display inline-block
      top 0
      left 50%
      transform translateX(-50%)
      transition all 300ms ease-in-out

      svg
        width 26px
        height 16px
        fill #fff
        transform rotate(-90deg)
        margin-right 17px
      &:hover
        box-shadow 1px 4px 6px rgba(253, 222, 16, 0.16)
        background-color #fdde10
        svg
          fill #000

    .top-footer
      margin-bottom 50px
      padding-top 133px

      .first-top-footer
        +breakpoint-down('md')
          margin 0px auto 20px 0px

        +breakpoint-down('sm')
          margin-right 0px


        .box-matn
          box-shadow 3px 11px 6px rgba(96, 1, 255, 0.02)
          border-radius 10px
          background-color #fff
          padding 25px

          +breakpoint-down('xl')
            padding 8px
          +breakpoint-down('sm')
            margin-bottom 40px

          h3
            color #6001ff
            font-size 14px
            font-weight 700
            line-height 19px
            text-align center
            position relative

            &:before
              content ''
              width 40px
              height 2px
              border-radius 1px
              background-color #4f03cf
              opacity 0.08
              display inline-block
              position absolute
              bottom -69%
              right 40%
              +breakpoint-down('sm')
                display none


          .phone-number
            padding-top 38px
            position relative

            +breakpoint-down('sm')
              padding-top 10px

            h4
              direction ltr
              text-align left
              color #000
              font-size 19px
              font-weight 300
              strong
                color #6001ff
            svg
              width 20px
              height 25px
              fill #6001ff
              position absolute
              top 60%
              right 88%
              +breakpoint-down('sm')
                right 69%
              +breakpoint-down('lg')
                right 83%
              +breakpoint-down('md')
                right 84%


            .number1
              text-align center
            .number2
              margin-top -24px
              text-align center

          .address
            margin-top 20px
            margin-right 15px
            margin-bottom 15px
            position relative
            // +breakpoint-down('sm')
            //   margin-right 94px
            // +breakpoint-down('lg')
            //   margin-right 81px
            +breakpoint-down('md')
              margin 0px auto

            p
              color #000
              font-size 12px
              font-weight 400
              line-height 23px

              +breakpoint-down('md')
                text-align center
            svg
              width 18px
              height 18px
              fill #6001ff
              left 2%
              bottom 26%
              position absolute
              +breakpoint-down('sm')
                left 22%
              +breakpoint-down('lg')
                left 14%
              +breakpoint-down('md')
                left 9%
              +breakpoint-down('sm')
                left 41%
      .iran-host

        +breakpoint-down('lg')
          padding 0px
          margin-bottom 40px
        +breakpoint-down('sm')
          text-align center

        .foot-item
          margin 0 20px


          +breakpoint-down('1200px')
            margin 0px

        h3
          color #6001ff
          font-size 14px
          font-weight 700
          margin-bottom 30px
          position relative
          white-space nowrap


          &:before
            content ''
            width 40px
            height 2px
            border-radius 1px
            background-color #4f03cf
            opacity 0.08
            display inline-block
            position absolute
            bottom -15px
            right 24px

            +breakpoint-down('sm')
              display none

        ul

          line-height 41px
          +breakpoint-down('xl')
            padding-right 0px
          li


            a
              color #000000
              font-size 12px
              font-weight 400
              line-height 44px
              height 32px
              border-radius 15px
              display inline-block
              line-height 32px
              padding 0 10px

              &:hover


                background-color #6001ff
                box-shadow 2px 9px 16px rgba(96,1,255,0.21)
                color #fff
      .namad
        +breakpoint-down('xl')
          padding 0px
        +breakpoint-down('md')
          margin 0px auto


        .namad-slide


          .img-namad
            img
              padding 25px 41px
              box-shadow 3px 11px 6px rgba(96, 1, 255, 0.02)
              border-radius 10px
              background-color #ffffff
              margin-right 80px

              +breakpoint-down('md')
                margin 0px auto
                display table




      .icons
        margin-right 70px
        margin-top 20px

        svg
          margin-right 18px
          margin-top 10px
          width 16px
          height 14px
          fill #000
          &:hover
            fill #6001ff

        +breakpoint-down('md')
          margin 0px auto
          display table
          padding-top 15px

    .bottom-footer
      .right-text
        color #000000
        font-size 13px
        font-weight 400
        +breakpoint-down('sm')
          margin-bottom 20px
          font-size 10px
        +breakpoint-down('md')
          margin-bottom 20px
          font-size 12px
          text-align center

      .left-text
        flex 1



        .text-design2
          margin-right 240px
          margin-bottom 10px
          +breakpoint-down('xl')
            margin-right auto

          +breakpoint-down('md')
            margin 0px auto
          p
            color #000
            font-size 12px
            font-weight 300
            strong
              color #6001ff
              font-size 15px
              font-weight 700


              /******************************blog-edit****************/