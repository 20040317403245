// Base
//
// @author Am!n <ivahid.com>
// @copyright 2017 ivahid.com
//
html
  font-family base-font-family
  font-size base-font-size

body
  margin 0
  font-family base-font-size
  font-size rem( base-font-size )
  font-weight base-font-weight
  line-height base-line-height
  color base-text-color
  text-align base-text-align
  background-color base-background-color
  direction base-direction

