// Utilities are some widely used CSS classes
//
// @author Am!n <ivahid.com>
// @copyright 2017 ivahid.com
//
.clearfix
  clearfix()

.invisible
  opacity 0
  visibility hidden

.visible
  opacity 1
  visibility visible

.hide, .hidden
  display none

.force-hide
  display none !important

.text-hide
  font 0/0 a
  color transparent
  text-shadow none
  background-color transparent
  border 0

.m-center
  margin-auto()

.middle
  align-middle()

.all-middle
  position absolute
  push-center()

.horizental-center
  position relative
  push-center('horizental')

.vertical-center
  position relative
  push-center('vertical')

.self-center
  align-self center

.ltr
  direction ltr

.rtl
  direction rtl

.go-right
  float right

.go-left
  float left

.circle
  circle()

.grayscale
  filter-grayscale()

.ungrayscale
  filter-grayscale(0)

.flip-h
  flip()

.flip-v
  flip('vertical')

.in-height p
  line-height inherit

.hr
  margin-top 20px
  margin-bottom 20px
  border 0
  border-top 1px solid #eee

.no-p
  padding 0px

.says,
.screen-reader-text
  sr-text()

.sbs-media
  grid-row()

  .sbs-content
    flex 1
    margin-right 1em
    min-width 0px

.abs-fill
  size(100%)
  position absolute
  top 0
  right 0
  z-index 2
