/*
 * Main styles
*
* Including common elements styles + Main page elements styles
*/
// body
//   &:before
//     content ''
//     min-height 777px
//     position absolute
//     left 0
//     top 0
//     background-color #6001ff
//     clip-path url(#header_background)
//     width 100%
.site-wrap
  position relative

body
  width 100%
  overflow-x hidden

.top-backgrounds
  position relative
  z-index -1

  &:before,
  &:after
    content ''
    position absolute
    width 100%
    top 0
    right 0
    min-height 777px
    background-color #6001ff
    -webkit-clip-path url(#header_background1)
    clip-path url(#header_background1)
    z-index 2
    +breakpoint-down('lg')
      min-height 590px

  &:after
    background-color #f2f3f8
    z-index 0
    transform translateY(90px)

    +breakpoint-down('lg')
      min-height 590px



body:not(.home)
  .top-backgrounds
    position relative
    z-index -1

    &:before , &:after
      content ''
      position absolute
      width 100%
      background-color #6001ff
      -webkit-clip-path url(#header_background1)
      clip-path url(#header_background1)
      z-index 2
      overflow hidden
      min-height 430px

    &:after
      background-color #f2f3f8
      z-index 0
      transform translateY(90px)
      min-height 251px
      transform translateY(196px) rotate(-3deg)


    // .top-backgrounds
    //   &:before
    //     min-height 250px
    //     transform translateY(196px) rotate(-3deg)
    //     width 110%
    //     right -9px
    //     z-index -5


    //   &:after
    //     min-height 251px
    //     transform translateY(196px) rotate(-3deg)
    //     width 110%
    //     right -9px
    //     z-index -4

.search-box

  +breakpoint-down('lg')
    margin-bottom 150px

  .right-search
    margin-top 95px
    margin-right 85px
    flex 1

    +breakpoint-down('lg')
      margin-right 0px
      margin-left auto

    .text
      margin-bottom 37px
      text-align center


      h3
        color #ffffff
        font-size 18px
        font-weight 700


      p
        color #ffffff
        font-size 12px
        font-weight 300

    form
      position relative

      input
        border 0
        width 100%
        height 58px
        box-shadow 3px 11px 6px rgba(96, 1, 255, 0.02)
        border-radius 29px
        background-color #ffffff
        direction ltr
        text-align left
        padding 0 20px
        position relative


      button
        padding 9px 22px
        border-radius 19px
        background-color #6001ff
        border 0
        position absolute
        top 50%
        right 15px
        transform translateY(-50%)
        transition all 200ms ease-in-out
        box-shadow 0 2px 13px rgba(96,1,255,0.15)
        a
          color #fff
        &:hover
          transform translateY(-50%) scale(1.05)
          text-shadow 0px 1px 4px #3dd0ef

  .left-search
    max-width 60%

    img
      -webkit-backface-visibility hidden
      backface-visibility hidden
      transform translate(0)
      margin-top -70px
      margin-right 18px
      /***********************end(search-box)**********/

#bubbles
  position absolute
  size(100%)
  z-index -2
  top 0
  right 0

  canvas
    display block
    margin 0
    padding 0

.security
    .item-security
      text-align center
      margin-top -50px
      margin-right -9px

      +breakpoint-down('lg')
        margin-bottom 70px
        margin-right 0px

      .line-before,
      .line-after
        content ""
        border 1px solid #f2f3f9
        width calc( ( 100% - 102px ) / 2 )
        position: absolute
        transform rotate(-19deg)
        top 216px
        left 13px

        &:before
          content ''
          position absolute
          size(100%)
          top 0
          right 0
          // animation-name fillSecurityLines
          // transform-origin right
          // animation-duration 4000ms
          // animation-timing-function linear
          // animation-delay 0
          // animation-fill-mode both
          // // animation-iteration-count infinite
          // transform scaleX(0)

        +breakpoint-down('xl')
          top 228px


      .line-before
        width calc( ( (100% - 102px) / 2 ) + 25px )
        right -13px
        top 147px

      &.right-security
        +breakpoint-down('lg')
          margin-top 0px
        .icon1
          width 99px
          height 82px
          position absolute
          top 2%
          right 13%
          transform rotateY(-161deg)
        .icon2
          width 60px
          height 60px
          position absolute
          right 68%
          top 13%


        .line-before
          // transform rotate(45deg)
          transform: rotate(21deg)
          top 219px
          right -19px
          border-image linear-gradient(to right, #f2f3f9, rgba(0, 0, 0, 0)) 1 round

          &:before
            transform scaleX(0)
            border-image linear-gradient(to right, #670dff, rgba(0, 0, 0, 0)) 1 round

        .line-after
          &:before
            animation-delay 600ms

      &.center-security
        .line-before
          &:before
            animation-delay 1200ms
        .icon1
          width 99px
          height 82px
          position absolute
          top 2%
          right 13%
          transform rotateY(-161deg)

        .icon3
          width 60px
          height 60px
          position absolute
          top 25%
          right 66%
          trasform rotateY(164deg)

        .line-after
          &:before
            animation-delay 1800ms

      &.left-security
        .line-before
          &:before
            animation-delay 2400ms
        .icon4
          position absolute
          width 110px
          height 110px
          top -8px
          right 13%
        .icon5
          position absolute
          width 65px
          height 65px
          right 68%


        .line-after
          transform: rotate(18deg)
          top 163px
          border-image linear-gradient(to right, rgba(0, 0, 0, 0), #f2f3f9 ) 1 round

          &:before
            border-image linear-gradient(to right, rgba(0, 0, 0, 0), #670dff ) 1 round
            animation-delay 3000ms
            animation-timing-function easing('out-expo')

      .image
        position relative
        display table
        margin 0 auto


        img
          position relative
          z-index 1000

        .circle
          width 102px
          height 61px
          border-radius 100%
          border 3px solid  #f2f3f9
          position absolute
          padding 8px 17px
          bottom -12px
          left 50%
          transform translateX(-50%)

          &:before
            content ''
            display inline-block
            size(100%)
            background-color #f2f3f9
            border-radius 100%

        .line-right , .line-left
          position absolute
          top 0
          right -28px
          font-size 0px

          &:before ,
          &:after
            content ''
            size(5px,100px)
            background-image linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(122,52,139,1) 50%,rgba(119,48,137,1) 51%,rgba(125,56,142,1) 53%,rgba(255,255,255,0) 100%)
            opacity 0.48
            display inline-block
            margin 0px 3px

          &:before
            transform translateY(42px)

        .line-left
          right auto
          left -28px

          &:before
            transform translateY(-24px)

          &:after
            transform translateY(24px)



      svg
        width 50px
        height 50px
        fill #6001ff
        position absolute
        top 24%
        right 6%

      .texts
        margin-top 50px


        h3
          color #6001ff
          font-size 14px
          font-weight 700
          margin-bottom 10px
        p
          color #000000
          font-size 12px
          font-weight 300
          line-height 20.01px

      .point
        width 25px
        height 5px
        position absolute
        top 58%
        right 46%

        +breakpoint-down('md')
          display none


    .center-security
      text-align center
      margin-top 70px
      +breakpoint-down('md')
        margin-top 0px
      +breakpoint-down('lg')
        margin-top 0px

      .point
        top 73%
        right 47%

        +breakpoint-down('md')
          display none




    .left-security
      text-align center
      margin-top 190px
      +breakpoint-down('md')
        margin-top 0px
      +breakpoint-down('lg')
        margin-top 0px

      .point
        top 58%
        right 47%

        +breakpoint-down('md')
          display none


      /********************************/

.servers
  position relative
  margin-top -198px

  .genera-servers
    padding-top 101px

  &:before,
  &:after
    content ''
    position absolute
    size(100%)
    top 0
    right 0
    background-color #6001ff
    -webkit-clip-path url(#servers_background)
    clip-path url(#servers_background)
    min-height 1100px
    z-index -1

    +breakpoint-down('lg')
      content none

  &:after
    min-height 1140px
    -webkit-clip-path url(#servers_background2)
    clip-path url(#servers_background2)
    background none
    background-image linear-gradient( to right, #f7f5f9 0%, rgba(255, 255, 255, 0) 75% )
    top 320px
    z-index -2

  .genera-servers

    & > .row
      margin 0px  -6.5px
    .dw-servers
      +breakpoint-down('lg')
        flex-wrap nowrap
        overflow-x auto
        justify-content flex-start
        padding-top 20px


    .starter
      margin 70px 130px 90px 0px
      +breakpoint-down('md')
        margin 0px auto 70px auto


      svg
        width 29px
        height 29px
        fill #6001ff
        align-self center
        +breakpoint-down('md')
          margin-top 75px
      .txts
        +breakpoint-down('md')
          margin-top 75px


      h3
        color #6001ff
        font-size 18px
        font-weight 700
        small
          color #000000
          font-size 12px
          font-weight 400


    .server-item-wrapp
      padding 0px 6.5px

      +breakpoint-down('lg')
        flex 0 0 88%
        width 88%


      .item-server
        background-color #fff
        box-shadow 0 7px 11px 2px rgba(96, 1, 255, 0.08)
        border-radius 10px
        position relative
        transition all 400ms ease-in-out
        +breakpoint-down('lg')
          margin-bottom 35px

        .abs-fill
          z-index 10



        &:hover
          .first-title
            box-shadow 0 7px 11px 11px rgba(229, 222, 242, 0.11)



            .back
              fill #5c0ae6



            .txt
            span
            .icon-crown
            small
              fill #fff
              color #fff !important

        .first-title
          margin-bottom 40px
          padding 29px 50px 0 5px
          +breakpoint-down('xl')
            padding: 19px 28px 0 5px
          +breakpoint-down('lg')
            padding 31px 78px 31px 5px
          +breakpoint-down('md')
            padding 27px 32px 1px 5px

          .back2 , .back
            position absolute
            width 100%
            height auto
            top -1px
            right 0
            z-index 0
            border-radius 10px
            border-bottom-right-radius 0
            border-bottom-left-radius 0
            transition all 200ms ease-in-out


            +breakpoint-down('md')
              top -25px !important

          .back
            z-index 1
            fill #f9f5ff



          .icn
            position relative
            z-index 4
            align-self center

            svg
              width 38px
              height 38px
              fill #6001ff
              z-index 1000
              align-self center

            .label
              position absolute
              bottom -2px
              left 23px
              height 18px
              box-shadow 0 2px 6px #fdde10
              border-radius 9px
              background-color #fdde10
              padding 0 8px
              color #ffffff
              font-size 12px
              font-weight 700
              line-height 20px

            .ribbon
              width 89px
              height 25px
              border-radius 13px
              background-color #6001ff
              line-height 25px
              position absolute
              top -53px
              right 128%
              box-shadow none
              text-align center
              color #fff

          .texts
            position relative
            z-index 5
            margin-right 20px

          .txt
            color #6001ff
            font-size 18px
            font-weight 700
            line-height 26px
            z-index 1000

            small
              color #000000
              font-size 12px
              font-weight 300
              z-index 1000


        .property
          padding-right 35px
          margin-top 22px
          +breakpoint-down('xl')
            padding-right 19px
          +breakpoint-down('lg')
            padding-right 25px
          +breakpoint-down('md')
            padding 35px 25px 15px 0px

          ul
            li
              color #000000
              font-size 12px
              font-weight 400
              line-height 30px

              &::before
                content ''
                background-image url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAICAYAAADA+m62AAAAwklEQVQoU4WQsUrDABRFz80Qurk4VHAr4tTVxbkgTu4OSaXYyaF/IPQHnBwymcUl+AUuLWmX7t066gcUokKoucVCSimob3wc7n3niX8mxheGM/3FRfhc4lVm8CsY4zYiRzw8VbrXDT5twPsjKur0CLckcouXtNLdz15R4GeZow9zmaGvGDcRE4tZWnEN8ga8xYelGAsWgel+i5HgrTBXGSrrls2NPXy8ElPgAJiHppOgz13RrUyMTwgYhhX9BC33v7EGl747rBdbWrwAAAAASUVORK5CYII=")
                display inline-block
                width 10px
                height 8px
                margin-left 10px

        .bottom-text
          padding 10px 30px 20px 0px
          align-items center

          small
            padding-left 15px

            +breakpoint-down('md')
              padding-left 7px


          &:hover
            span
              background-color #fdde10
              a
              svg
                color #000
                fill #000
                transition all 300ms ease-in-out
          p
            color #000000
            font-size 12px
            font-weight 300

          strong
            color #6001ff
            font-weight 700
            font-size 17px

          .view-btn
            width 93px
            height 38px
            border-radius 19px
            background-color #6001ff
            line-height 38px
            text-align center
            font-size 12px
            font-weight 400
            color #fff
            box-shadow 2px 9px 16px rgba(96,1,255,0.21)
            transition all 200ms ease-in-out


            svg
              width 10px
              height 10px
              fill #fdde10

        &:hover
          .bottom-text
            .view-btn
              background-color #fdde10
              box-shadow 1px 4px 16px rgba(253,222,16,0.10)
              color #000

              svg
                fill #000

      &:hover
        .item-server
          .first-title
            .icn
              span
                background-color #fdde10

                p
                  color #000


    .forth-server
      .item-server
        .first-title
          .icn
            span
              width 62px
              height 25px
              border-radius 13px
              right 173%


    /*********************end(servers)********************/

.choice
  margin-top 207px
  margin-bottom 40px
  +breakpoint-down('md')
    margin-top 0

  .choice-item

    .pic-box
      position relative
      margin-top 49px

      .pic2


        img
          z-index 1000
          bottom -1%
          right 53%
          position absolute
          z-index 1000

          // &:before
          //   content ''
          //   width 9px
          //   height 9px
          //   background-color #63dcff
          //   position absolute
          //   display inline-block



        .icon3
          position absolute
          z-index -1
          bottom -59%
          right 4%

        .icon2
          position absolute
          z-index 1
          bottom -39%
          right 30%
        .icon1
          position absolute
          top -65%
          right 32%

    .txt-box
      margin-right 49px
      margin-top -4px
      flex 1
      z-index 1000


      h2
        color #6001ff
        font-size 18px
        font-weight 700
        margin-bottom 15px

        +breakpoint-down('md')
          text-align center

      p
        color #2e2e2e
        font-size 12px
        font-weight 400
        line-height 25.01px

        +breakpoint-down('md')
          text-align justify

      +breakpoint-down('xl')
        margin-right 0px
      +breakpoint-down('md')
        margin 30px auto


        span
          font-weight 700

    .newsletter
      z-index 1000
      margin-top 53px
      margin-right auto
      position relative
      margin-left 40px
      +breakpoint-down('xl')
        margin 0px auto
      // +breakpoint-down('md')
      //   margin-right 85px
      //   margin-top 40px
      // +breakpoint-down('sm')
      //   margin-right 99px


      .matn
        text-align center
        h2
          color #6001ff
          font-size 18px
          font-weight 700
          line-height 30px
        p
          color #010101
          font-size 12px
          font-weight 300
      form
        position relative

        input
          border 0
          width 100%
          height 58px
          box-shadow 3px 11px 6px rgba(96, 1, 255, 0.02)
          border-radius 29px
          background-color #ffffff
          padding 0 20px
          position relative
          color #6001ff
          font-size 12px
          font-weight 300
          margin-top 30px

        button
          padding 7px 16px
          border-radius 19px
          background-color #6001ff
          border 0
          position absolute
          top 66%
          left 3%
          transform translateY(-50%)
          box-shadow 3px 11px 6px rgba(96, 1, 255, 0.04)
          &:hover
            transform translateY(-50%) scale(1.05)
            box-shadow 3px 11px 6px rgba(96, 1, 255, 0.04)
            text-shadow 0px 1px 4px #3dd0ef

          a
            color #fff
            /***************end(choice)*******/

.customer-testimonials
  .swiper-slide
    text-align center

  .customer-wrap
    margin-top 100px
    margin-bottom 100px

    +breakpoint-down('lg')
      overflow scroll

    +breakpoint-down('md')
      margin-bottom 30px

    h3
      color #6001ff
      font-size 16px
      font-weight 700
      position relative
      margin 0px auto
      display table

      &:before
        content ''
        width 40px
        height 2px
        border-radius 1px
        background-color #4f03cf
        opacity 0.08
        display inline-block
        position absolute
        bottom -23px
        right 42%
        transition all  300ms ease-in-out
        transform translateY(-50%)

    .swiper-container
      margin-top 90px

      // ul
      //   display flex
      //   justify-content center
      //   cursor pointer

      //   li
      //     padding 0px 25px
      //     min-height 100px
      //     min-width 150px
      //     position relative

      &::before
        position absolute
        content ''
        width 126px
        height 166px
        border-radius 83px
        background-color #0e2640
        background-image linear-gradient(to top, rgba(255, 255, 255, 0) 24%, #6001ff 100%)
        opacity 0
        left 50%
        top -9%
        transform translateX(-50%)
        -webkit-mask-image linear-gradient(to top, rgba(255, 255, 255, 0) 24%, #6001ff 100%)
        -ms-mask-image linear-gradient(to top, rgba(255, 255, 255, 0) 24%, #6001ff 100%)
        mask-image linear-gradient(to top, rgba(255, 255, 255, 0) 24%, #6001ff 100%)




          img
            size(61px)
            border-radius 100%
            opacity 0.5
            transform translateY(-50%)
            position relative
            top 50%
            transition all 200ms ease-in-out
            border 3px solid rgba(0, 0, 0, 0)



          &.active
            img
              size(95px)
              border-color #6001ff
              opacity 1

            &:before
              opacity 0.2

      .tab-content
        margin-top 50px

        .author-name
          display block
          color: #6001ff
          font-size 16px
          font-weight 700
          line-height 25.01px

        .auther-job
          color #000000
          font-size 12px
          font-weight 300
        p
          color #2e2e2e
          font-size 12px
          font-weight 400
          margin-top 20px


    .comment
      a
        padding 1px 21px
        box-shadow 1px 4px 6px rgba(253, 222, 16, 0.16)
        border-radius 20px
        background-color #fdde10
        display inline-block
        line-height 39px
        color #000000
        font-size 12px
        font-weight 400
        margin-bottom 20px !important
        margin-top 40px
        transition all 300ms ease-in-out
        margin 0px auto
        display table

        &:hover
          background-color #6001ff
          color #ffffff
          box-shadow 0 2px 13px rgba(96,1,255,0.15)

        /********************************end(customer)**********/
  .customers-slider-paginations
    margin 0px auto 20px auto
    display table

    .swiper-pagination-bullet
      margin 0px 4px

    .swiper-pagination-bullet-active
      background #6001ff


  .swiper-slide
    padding 0px 15px
    img
      border-radius 100%
      transition all 200ms ease-in-out
      border 3px solid rgba(0,0,0,0)
      // width 61px
      // height 61px

    .testimonial
      .author-name
        display block
        color #6001ff
        font-size 16px
        font-weight 700
        line-height 25.01px
        padding-top 20px
        padding-bottom 15px

      p
        color #2e2e2e
        font-size 12px
        font-weight 400
        margin-top 20px


  .swiper-slide-active
    width 80%

    &:first-child,
    &:last-child
      width 95%

    .customer-avatar
      img
        width 95px
        height 95px
        border-color #6001ff
        opacity 1
        filter none

  .swiper-slide-prev
    text-align left
    position relative

    &:after
      content ''
      position absolute
      background-image linear-gradient(to top, #f9f9f9 24%, #f7f4fb 100%)
      // background-color #f1eff2
      width 100%
      height 110%
      top -11px
      left -20px
      filter blur(30px)
      z-index 2

  .swiper-slide-next
    text-align right
    position relative

    &:before
      content ''
      position absolute
      background-image linear-gradient(to top, #f9f9f9 24%, #f7f4fb 100%)
      width 100%
      height 110%
      top -11px
      left -20px
      filter blur(30px)
      z-index 2




.customers-logo
  position relative

  &:before
    content ''
    position absolute
    size(105%, 100%)
    top -370px
    right 0
    background-image linear-gradient(to left, #f7f5f9 0%, rgba(255,255,255,0) 75%)
    -webkit-clip-path url(#customer_logo_background)
    clip-path url(#customer_logo_background)
    min-height 1141px
    z-index -1

  .logo-wrap
    position relative
    figure
      margin-top -30px
      position relative
      grid-row()

      img
        position relative
        z-index 1000

       &:before
        content ''
        width 71px
        height 13px
        background-color #6001ff
        opacity 0.05
        position absolute
        display inline-block
        z-index 1000
        right -7%
        border-radius 100%
        bottom -3%

        +breakpoint-down('lg')
          content none

      .back4
        position absolute
        right -38%
        bottom -29%

        +breakpoint-down('lg')
          display none

      figcaption
        align-self center
        margin-right -20px
        margin-top 40px
        color #6001ff
        font-size 16px
        font-weight 700
        position absolute
        left 43px
        bottom 78px

        +breakpoint-down('sm')
          margin 20px auto

        &:before
          content ''
          width 40px
          height 2px
          border-radius 1px
          background-color #4f03cf
          opacity .08
          display inline-block
          position absolute
          bottom -16px
          right 34%

          +breakpoint-down('lg')
            content none

    .customer-slider
      position relative
      margin-top 50px
      min-width 0
      flex 1


      .customer-slider-prev
        position absolute
        left 34px
        top -24%
        fill #1b2c7a
        opacity 0.37

        +breakpoint-down('lg')
          right 94%

      .customer-slider-next
        position absolute
        left 51px
        top -10%
        fill #6001ff
        +breakpoint-down('lg')
          left 55px

      .swiper-container
        padding 0px 20px
        margin-right -20px

        +breakpoint-down('sm')
          padding 0 auto
          margin 0 auto


        .swiper-wrapper
          padding 20px 0px


          .swiper-slide
            position relative
            box-shadow 3px 11px 6px rgba(96, 1, 255, 0.02)
            border-radius 10px
            background-color #fff
            display block
            text-align center
            line-height 108px
            height 108px
            width 100%
            transition all 200ms ease-in-out
            &:hover
              box-shadow 6px 6px 9px rgba(59, 37, 146, 0.15)



            /**********************end(customer-logo)**************/

.education-content
  position relative

  &:before
    content ''
    position absolute
    size(57%, 250px)
    top 45%
    transform translateY(-50%)
    right 0
    background-color alpha(#6001ff, 10%)
    -webkit-clip-path url(#blogposts_background)
    clip-path url(#blogposts_background)


    +breakpoint-down('md')
      content none

  .education-wrap
    margin-top 150px
    margin-bottom 100px

    +breakpoint-down('md')
      margin-top 50px

    .educations
      .swiper-container
        .swiper-wrapper
          padding 40px 0

          // .swiper-slide
          //   +breakpoint-down('lg')
          //     width auto


    .education-pic
      box-shadow 3px 11px 6px rgba(96, 1, 255, 0.02)
      border-radius 10px
      background-color #fff
      padding 14px
      margin 0px
      position relative
      transition all 300ms ease-in-out

      img
        width 100%



      &:hover
        transform translateY(-25px)
        .txts
          .icon-search
            background-color #fdde10

      .txts
        text-align center
        margin-bottom 30px
        h4
          color #111c4c
          font-size 12px
          font-weight 400
          line-height 26.8px
          margin-top 15px
        p
          color #111c4c
          font-size 11px
          font-weight 300
          margin-bottom 15px

        .icon-search
          background-color #f7f1ff
          width 40px
          height 40px
          border-radius 100%
          line-height 40px
          display inline-block
          position absolute
          bottom -7%
          left 50%;
          transform translateX(-50%)
          z-index 1000
          transition all 300ms ease-in-out

          svg
            line-height 40px
            margin-right -2px

    .all-education
      align-self center
      text-align center

      .blogposts-slider-paginations
        display flex
        align-items center
        justify-content center
        margin 0px 10px
        .swiper-pagination-bullet
          margin 0px 3px



        .swiper-pagination-bullet-active
          width 24px
          height 24px
          display inline-block
          box-shadow 0 0 0 2px #e7dbfa
          border 5px solid #fff;
          background-color #6001ff
          border-radius 100%
          margin 0px 10px




      .pointer
        padding 0 120px
        position relative
        +breakpoint-down('xl')
          padding 0px 95px
        +breakpoint-down('lg')
          padding 0px 50px

        .right-point , .left-point
          width 4px
          height 4px
          background-color #6001ff
          opacity 0.13
          border-radius 100%

        .center-point
          width 10px
          height 10px
          background-color #6001ff
          border-radius 100%
          margin 0px 15px

          &:before
            content ''
            width 20px
            height 20px
            border 2px solid #6001ff
            opacity 0.12
            display inline-block
            border-radius 100%
            position absolute
            padding 9px
            right 35%
            top -6px
            transform translateX(17%)

            +breakpoint-down('lg')
              right 28%
      .text

        h3
          color #6001ff
          font-size 16px
          font-weight 700
          line-height 25.01px
          margin-top 60px
          position relative

          &:after
            content ''
            width 40px
            height 2px
            border-radius 1px
            background-color #4f03cf
            opacity 0.08
            display inline-block
            position absolute
            top 155%
            right 43%
            +breakpoint-down('1200px')
              content none


      .all-observe
        margin-top 65px

        a
          color #000
          font-size 12px
          font-weight 400
          padding 0px 16px
          box-shadow 1px 4px 6px rgba(253, 222, 16, 0.16)
          border-radius 20px
          background-color #fdde10
          display inline-block
          line-height 39px
          &:hover
            background-color #6001ff
            color #ffffff
            box-shadow 0 2px 13px rgba(96,1,255,0.15)

          /**************************************blog-edit***********************/

.site-news
  margin-bottom 56px
  position relative
  z-index 900
  // margin-top -287px
  .news-wrap
    margin-top 20px
    +breakpoint-down('md')
      margin-bottom 20px
    .news-item
      box-shadow 3px 11px 6px rgba(96, 1, 255, 0.02)
      border-radius 10px
      background-color: #ffffff
      position relative
      padding-bottom 30px
      height 100%

      &:hover
        .pic-news
          &::before
            opacity 0
          figcaption
            a
              color #fff
              background-color #6001ff
        .point-icon
          opacity 1


      .pic-news
        margin 0px
        margin-bottom 10px
        position relative

        &::before
          content ''
          position absolute
          box-shadow: 3px 11px 6px rgba(96,1,255,0.0)
          border-radius 9px 9px 0 0
          background-color rgba(96,1,255,0.42)
          top 0
          right 0
          z-index 47
          width 100%
          height 92%
          border-radius 10px
          opacity 1
        &::after
          content ''
          position absolute
          background linear-gradient(to bottom, rgba(0,0,0,0) 2%, #fff 85%)
          width: 100%
          height 100%
          top 0
          right 0
          z-index 100
        img
          border-radius 10px
          // -webkit-mask-image linear-gradient(to bottom, rgba(0,0,0,1) 20%,rgba(0,0,0,0) 100%)
          // mask-image linear-gradient(to bottom, rgba(0,0,0,1) 20%,rgba(0,0,0,0) 100%)
          position relative
          width 100% !important
          z-index 30

        figcaption
          position relative

          a
            color #6001ff
            font-size 11px
            font-weight 400
            position absolute
            bottom 10px
            left 50%
            padding 0 10px
            height 26px
            border-radius 13px
            background-color #f2f3f8
            display inline-block
            line-height 26px
            text-align center
            transition all 300ms ease-in-out
            transform translateX(-50%)
            z-index 1000
      h2
        color #111c4c
        font-size 14px
        font-weight 400
        padding 0px 25px
      .txt
        color #111c4c
        font-size 11px
        font-weight 300
        padding 0px 25px
        margin-bottom 30px

      .text-news
        color #2e2e2e
        font-size 12px
        font-weight 400
        padding 0px 25px
        padding-top 12px
        max-height 70px
        overflow hidden
        mask-image linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%)

      .point-icon
        width 44px
        height 44px
        box-shadow 2px 6px 6px rgba(253, 222, 16, 0.48)
        background-color #fdde10
        border-radius 100%
        display inline-block
        line-height 44px
        bottom -5%
        left 50%
        transform translateX(-50%)
        position absolute
        transition all 300ms ease-in-out
        opacity 0
        svg
          margin-right 12px
          /*************************end(site-news)*****************/
.weblog-site
  .weblog-wrap
    margin-bottom 60px
    .pic-blog
      padding-right 50px

      +breakpoint-down('lg')
        padding-right 0px

      +breakpoint-down('md')
        display none

    .text-blog
      padding 68px 60px 0px 0px

      +breakpoint-down('lg')
        padding 68px 0px 0px

      +breakpoint-down('md')
        width 100%
        padding 0px

      .top-text
        h3
          color #6001ff
          font-size 16px
          font-weight 700
          padding-bottom 10px

        .txt-content
          padding-bottom 50px

          svg
            width 11px
            height 11px
            fill #fdde10
            align-self center
          .matn-blog
            font-size 12px
            font-weight 300
            margin-right -2px
            a
              color #000000
              padding-left 6px
              padding-right 9px

            strong
              font-weight 700
              padding-right 6px

      .bottom-text

        span
          color #000000
          font-size 12px
          font-weight 400
        a
          padding 0px 25px
          color #6001ff
          font-size 12px
          font-weight 400
          height 37px
          box-shadow 3px 11px 6px rgba(96, 1, 255, 0.02)
          border-radius 19px
          background-color #ffffff
          display inline-block
          line-height 37px
          margin-right 10px
          transition all 300ms ease-in-out
          +breakpoint-down('sm')
            padding 0px 14px

          &:hover
            background-color #6001ff
            color #ffffff

    .search-blog
      position relative
      flex 1
      padding-top 33px

      form
        position relative
        margin-bottom 60px

        input
          border 0
          width 100%
          height 57px
          box-shadow 3px 11px 6px rgba(96, 1, 255, 0.02)
          border-radius 29px
          background-color #fff
          padding 0 20px
          position relative
          color #6001ff
          font-size 12px
          font-weight 300
          margin-top 30px

        button
          width 91px
          height 37px
          border-radius 19px
          background-color #6001ff
          border 0
          position absolute
          top 58px
          left 14px
          transform translateY(-50%)
          color #fff
          font-size 12px
          font-weight 400

          &:hover
            transform translateY(-50%) scale(1.05)
            box-shadow 3px 11px 6px rgba(96,1,255,0.04)
            text-shadow 0 1px 4px #3dd0ef


    .mood-show
      float left
      margin-left 10px
      +breakpoint-down('sm')
        display table
        margin 0px auto
        float none



      a
        svg
          fill alpha(#6001ff, 20%)
          size(15px)

      .txt-show
        color #6001ff
        font-size 14px

      svg
        margin-right 10px

      a
        &.active
          svg
            fill #6001ff
        /***********************************end(weblog-site)**************************/

@keyframes toastfadeout
  from
    bottom 30px
    opacity 1

  to
    bottom 0
    opacity 0

#dwtoast
  visibility hidden
  min-width 250px
  margin-left -125px
  background-color #333
  color #fff
  text-align center
  border-radius 2px
  padding 16px
  position fixed
  z-index 9990000
  left 50%
  bottom 30px

#dwtoast.show
  visibility visible
  animation toastfade 0.5s, toastfadeout 0.5s 2.5s


// .customer
//   .swiper-container
//     .swiper-slide-active
//       width 80%